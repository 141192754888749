import React from 'react';
import { Button, Tabs, Tab } from 'carbon-components-react';
import '@carbon/ibmdotcom-web-components/es/components/leadspace/index.js';
import Globe32 from '@carbon/icons-react/lib/globe/32';
import PersonFavorite32 from '@carbon/icons-react/lib/person--favorite/32';
import Application32 from '@carbon/icons-react/lib/application/32';

import { InfoSection, InfoCard } from '../Info';

const props = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'navigation',
  },
  tab: {
    href: '#',
    role: 'presentation',
    tabIndex: 0,
  },
};

const UnderDevelopment = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}>
      <h1>
        This site is under development. <br /> Please check back soon!
      </h1>
    </div>
  );
};

export default UnderDevelopment;
