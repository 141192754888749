import React from 'react';
import './app.scss';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import UIHeader from './components/UIHeader';
import { Route, Switch } from 'react-router-dom';
import UnderDevelopment from './components/UnderDevelopment/UnderDevelopment';

const App = () => (
  <>
    <UIHeader />
    <Content>
      <Switch>
        <Route exact path="/" component={UnderDevelopment} />
      </Switch>
    </Content>
  </>
);

export default App;
