import React from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  SideNav,
  SideNavItems,
  HeaderContainer,
  HeaderMenuButton,
  SkipToContent,
  HeaderSideNavItems,
} from 'carbon-components-react/lib/components/UIShell';
import { Link } from 'react-router-dom';

const hideSideNav = () => {
  var sidenav = document.getElementsByClassName(
    'bx--side-nav__navigation bx--side-nav bx--side-nav--expanded bx--side-nav--ux bx--side-nav--hidden'
  );
  sidenav[0].classList.remove('bx--side-nav--expanded');
};

const UIHeader = props => (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <>
        <Header
          aria-label="Header"
          onClick={isSideNavExpanded === true ? onClickSideNavExpand : null}>
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName href="/#/" prefix="Jack Robinson">
            Home
          </HeaderName>
          {/* <HeaderNavigation aria-label="Nav">
            <HeaderMenuItem element={Link} to="/about-me">
              About Me
            </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/contact">
              Contact
            </HeaderMenuItem>
          </HeaderNavigation> */}

          {/* <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}>
            <SideNavItems>
              <HeaderSideNavItems hasDivider={true}>
                <HeaderMenuItem element={Link} to="/" onClick={hideSideNav}>
                  Home
                </HeaderMenuItem>
                <HeaderMenuItem
                  element={Link}
                  to="/about-me"
                  onClick={hideSideNav}>
                  About Me
                </HeaderMenuItem>
                <HeaderMenuItem
                  element={Link}
                  to="/contact"
                  onClick={hideSideNav}>
                  Contact
                </HeaderMenuItem>
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav> */}
        </Header>
      </>
    )}
  />
);

export default UIHeader;
